import { useCookie } from "@netfront/common-library";
import { useDomain } from "@netfront/gelada-identity-library";
import { Button } from "@netfront/ui-library";
import { IconCross } from "components/Social";
import { useCurrentAgency } from "hooks";
import { useEffect, useState } from "react";

export const QualtricsCookieBanner = () => {
  const { getAgency } = useCurrentAgency();
  const { createCookie, getCookieValue, setCookie } = useCookie();
  const { getDomain } = useDomain();

  const [shouldShowBanner, setShouldShowBanner] = useState<boolean>(false);

  const QUALTRICS_COOKIE = 'hasDissmisedQualtrics';

  const onClose = () => {
    const qualtricsCookie = createCookie(QUALTRICS_COOKIE, 'true', {
      domain: getDomain(),
      storageExpiryOptions: {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 12,
        },
      },
    });

    setCookie(qualtricsCookie);
    setShouldShowBanner(false);
  };

  useEffect(() => {
    if(!getAgency()) return;

    const currentDate = new Date();
    const isBeforeDueDate = currentDate < new Date(currentDate.getFullYear(), 5, 30);
    if(!isBeforeDueDate) return;

    const hasDissmisedQualtrics = getCookieValue(QUALTRICS_COOKIE);
    if(hasDissmisedQualtrics) return;

    setShouldShowBanner(true);

  }, [getAgency()]);

  return shouldShowBanner ? (
    <div className="fixed bottom-0 left-0 w-full bg-white p-8 index-2 shadow">
      <button 
        className="absolute right-8 color-black w-10 pointer"
        onClick={onClose}
      >
        <IconCross />
      </button>
      
      <div className="container container-sm">
        <div className="flex items-center gap-8">
          <img 
            className="max-w-18"
            src="/images/hint-icon.svg" 
            alt="Hint" 
          />

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-12">
            <p className="mb-0">
              Help us improve Stable Ground! Complete a short survey about your experience, and we'll donate $20 to a charity of your choice.
            </p>
            <Button
                linkButton={{ 
                  id: 'qualtrics-survey', 
                  target: '_blank',
                  url: 'https://sydney.au1.qualtrics.com/jfe/form/SV_5uIYsusLrWQuvf8',
                }}
                text="Find out more"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null
};