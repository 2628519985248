import { useToggle } from '@netfront/common-library';
import { INotification, useSocialContext } from 'contexts';
import { DBMetadata } from 'interfaces';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ERelateType, useMarkANotificationSeen } from 'services';

import { DropdownMenu } from '../DropdownMenu';
import { Notifications } from '../Notifications';
import { getNotificationLink, getNotificationMessage } from '../Pages';
import { RelateIcon } from '../RelateIcon';

import { NotificationsMenuProps } from './NotificationsMenu.interfaces';

export const NotificationsMenu = ({ notifications = [] }: NotificationsMenuProps) => {
  const { push } = useRouter();

  const { isToggled: isNotificationsMenuOpen, toggle } = useToggle();
  const { markANotificationSeen } = useMarkANotificationSeen();
  const { notifications: headerNotifications, updateNotifications } = useSocialContext();

  return (
    <DropdownMenu
      additionalClassNames="c-notifications-menu spotlight-item--3 ml-auto"
      dropdownId="notifications"
      isDisplayContent={isNotificationsMenuOpen}
      trigger={<Notifications count={notifications.length} />}
      onDisplayContent={notifications.length > 0 ? () => toggle() : () => push('/notifications')}
    >
      <ul>
        {notifications
          .filter(({ seen }) => !seen)
          .slice(0, 5)
          .map((notification) => {
            const { content, id, metadata, guid } = notification;
            let relateType;

            let parsedMetaData;

            if (metadata) {
              parsedMetaData = JSON.parse(String(metadata)) as DBMetadata;
              relateType = parsedMetaData.relateType && ERelateType[parsedMetaData.relateType];
            }

            const link = getNotificationLink(notification, guid, parsedMetaData);
            return (
              <li key={id}>
                <button
                  className='c-notifications-menu__button'
                  onClick={() =>   markANotificationSeen({
                    variables: {
                      ipAddress: '',
                      notificationGuid: String(guid),
                      userAgent: '',
                    },
                  }).finally(() => {
                    updateNotifications(
                      headerNotifications.map((message) =>
                        message.guid === guid ? ({ ...message, seen: true } as INotification) : message,
                      ),
                    );
                    push(link);
                  })}
                >
                  <div className="c-notifications-menu__status">
                    <span className="h-hide-visually">Unread</span>
                  </div>
                  {relateType && <RelateIcon relateType={relateType} />}
                  <span className="c-notifications-menu__message">
                    {parsedMetaData ? `${parsedMetaData.displayedName ? String(parsedMetaData.displayedName) : ''} ${getNotificationMessage(parsedMetaData.type, parsedMetaData.relateType)}` : content}
                  </span>
                </button>
              </li>
            );
          })}
        <li>
          <NextLink href="/notifications">
            <span className='c-notifications-menu__button'>See all Notifcations ({notifications.length})</span>
          </NextLink>
        </li>
      </ul>
    </DropdownMenu>
  );
};
