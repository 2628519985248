import { getAccessTokenCookie } from '@netfront/common-library';
import { Button } from '@netfront/ui-library';
import { useCurrentAgency } from 'hooks';
import { useRouter } from 'next/router';

export const FeaturesSection = () => {
  const { push } = useRouter();
  const accessToken = getAccessTokenCookie();
  const { getAgency } = useCurrentAgency();

  const scrollToAgencies = () => {
    const agencies = document.getElementById('agencies') as HTMLElement;
    agencies.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <section className="py-16 md:py-28">
      <div className="container">
        <div className="flex items-center mb-6">
          <div className="flex-1">
            <h2 className="mb-2 mr-2">Features</h2>
          </div>
          <Button linkButton={{ id: 'features', url: '/features' }} text="View all" variant="secondary" />
        </div>

        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 p-2">
            <div
              className="bg-white border-2 border-athens rounded p-12 h-full transition-200 hover:border-primary hover:shadow-xl pointer"
              onClick={() => {
                accessToken || getAgency() ? push('/self-assessment/introduction') : scrollToAgencies();
              }}
            >
              <img alt="Check icon" className="max-w-20 mb-4" src="/images/feedback-icon.svg" />
              <h3 className="h4 mb-4">Self-assessment</h3>
              <p className="mt-auto line-1.2 mb-4">Do you want to know if your alcohol or other drug use is putting you at risk of harm?</p>
              <ul className="list-disc">
                <li className="line-1.2 ml-0">Our confidential self-assessment asks about your recent substance use and mental health</li>
                <li className="line-1.2 ml-0">Get personalised suggestions on what you could do to address your substance use </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2">
            <div
              className="bg-white border-2 border-athens rounded p-12 h-full transition-200 hover:border-primary hover:shadow-xl pointer"
              onClick={() => {
                accessToken || getAgency() ? push('/about-drugs') : scrollToAgencies();
              }}
            >
              <img alt="Speech bubble" className="max-w-20 mb-4" src="/images/resource-icon.svg" />
              <h3 className="h4 mb-4">Factsheets</h3>
              <p className="mt-auto line-1.2 mb-4">Do you want to learn about alcohol and other drugs?</p>
              <ul className="list-disc">
                <li className="line-1.2 ml-0">Over 10, evidence-based fact sheets covering legal and illegal substances </li>
                <li className="line-1.2 ml-0">Learn about what each substance is, the short- and long-term effects of using it, and support options that can help you to modify your use </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2">
            <div
              className="bg-white border-2 border-athens rounded p-12 h-full transition-200 hover:border-primary hover:shadow-xl pointer"
              onClick={() => push(accessToken ? '/community' : '/register')}
            >
              <img alt="Textbook" className="max-w-20 mb-4" src="/images/info-icon.svg" />
              <h3 className="h4 mb-4">GP letter</h3>
              <p className="mt-auto line-1.2 mb-4">Download a letter with your alcohol and other drug use and/or mental health results from your self-assessment to take to your GP appointment. </p>
              <ul className="list-disc">
                <li className="line-1.2 ml-0">Help start a discussion about alcohol and other drug use </li>
                <li className="line-1.2 ml-0">Customise which results appear on the GP letter</li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-2">
            <div
              className="bg-white border-2 border-athens rounded p-12 h-full transition-200 hover:border-primary hover:shadow-xl pointer"
              onClick={() => push(accessToken ? '/program' : '/register')}
            >
              <img alt="Computer" className="max-w-20 mb-4" src="/images/program-icon.svg" />
              <h3 className="h4 mb-4">Steady program</h3>
              <p className="mt-auto line-1.2 mb-4">Do you want research backed activities to help you change your substance use? </p>
              <ul className="list-disc">
                <li className="line-1.2 ml-0">Be guided through activities that aim to get you thinking about your substance use </li>
                <li className="line-1.2 ml-0">Learn some helpful skills and strategies that can help you change your substance use </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
